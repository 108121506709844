import React, { Component } from 'react';

class ShopPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Shop &nbsp; : &nbsp; Rendered Shop Component</p>
            </div>
        )
    }
}
export default ShopPageComponent