let TechnologiesData = [{
    "type": "Academic Technology",
    "tag":"Academic",
    "status":"Active",
    "title": "XCOLEARN ™",
    "url": "https://www.xcolearn.com",
    "description": "Introducing our innovative interactive learning platform that combines education with excitement. Users engage in enriching events, sessions, courses and earn rewards, which can be redeemed for cash. Designed to motivate and enhance the learning experience, our platform fosters growth and achievement in a fun, rewarding environment."
},{
    "type": "Mainframe Technology",
    "tag":"Mainframe",
    "status":"Active",
    "title": "XCOLOGY ™",
    "url": "https://www.xcology.net",
    "description": "Empowering businesses with cutting-edge software technology that drives efficiency, innovation, and growth. Our user-friendly solutions are designed to streamline operations, enhance productivity, and transform digital experiences. We cater to both B2B and B2C markets, providing scalable and robust platforms that meet the dynamic needs of modern enterprises."
}, {
    "type": "Business Technology",
    "tag":"Business",
    "status":"Under Development",
    "title": "XCOLONS ™",
    "url": "https://www.xcolons.com",
    "description": "Transforming businesses with advanced B2B and B2C software technology services. Our solutions drive efficiency, innovation, and growth by streamlining operations and enhancing digital experiences. We offer scalable, user-friendly platforms tailored to meet the diverse needs of modern enterprises, empowering them to achieve their strategic objectives."
}, {
    "type": "Financial Technology",
    "tag":"Finance",
    "status":"Upcoming",
    "title": "XCOFIN ™",
    "url": "https://www.xcofin.com",
    "description": "Providing comprehensive financial management solutions, including investment, asset management, deposits, payments, and credit systems. Our innovative services streamline financial operations, enhance asset growth, and ensure secure transactions, empowering clients to achieve their financial goals with confidence and efficiency."
}, {
    "type": "Gaming Technology",
    "tag":"Gaming",
    "status":"Upcoming",
    "title": "PIX RAIDER GAMES ™",
    "url": "https://www.pixraider.com",
    "description": "Leading the way in gaming technology, we develop immersive games for Android, PC, PlayStation, and Xbox. Our innovative solutions and cutting-edge graphics deliver exceptional gaming experiences, captivating players across multiple platforms. We transform creative concepts into engaging, high-quality games that push the boundaries of entertainment."
}]

export default TechnologiesData