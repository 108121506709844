import React, { Component } from 'react';
import TechnologiesData from '../../../../Model/Technology/Technologies';

class TechnologiesPageComponent extends Component {
    render() {
        let technologiesdata = TechnologiesData;
        return (
            <div>
                <br/>
                <h4><strong>Our Technologies</strong></h4>
                <p>Connect with our innovative technologies, designed to revolutionize academic, tech services and drive business growth with cutting-edge solutions.</p>
                <div className="accordion accordion-flush" id="xcaccordion">
                    {
                        technologiesdata.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="accordion-item xc-accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${item.tag}`} aria-expanded="true" aria-controls={`${item.tag}`}>
                                                <strong>{item.type}</strong>
                                            </button>
                                        </h2>
                                        <div id={`${item.tag}`} className="accordion-collapse collapse" data-bs-parent="#xcaccordion">
                                            <div className="accordion-body">
                                                <h4><strong>{item.title}</strong></h4>
                                                <small><b>Status : {item.status}</b></small>
                                                <br/>
                                                <p>{item.description}</p>
                                                <p><a href={item.url} target="_blank" rel="noopener noreferrer" className="btn btn-success xc-btn">Connect &nbsp; <i className="fa fa-wifi"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
export default TechnologiesPageComponent