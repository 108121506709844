import React, { Component } from 'react';

class PrivacyPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Privacy &nbsp; : &nbsp; Rendered Privacy Component</p>
            </div>
        )
    }
}
export default PrivacyPageComponent