import React, { Component } from 'react';

class PublishPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Publish &nbsp; : &nbsp; Rendered Publish Component</p>
            </div>
        )
    }
}
export default PublishPageComponent