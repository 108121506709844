import React, { Component } from 'react';

class SecurityPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Security &nbsp; : &nbsp; Rendered Security Component</p>
            </div>
        )
    }
}
export default SecurityPageComponent