import React, { Component } from 'react';

class CookiePageComponent extends Component {
    render() {
        return (
            <div>
                <p>Cookie &nbsp; : &nbsp; Rendered Cookie Component</p>
            </div>
        )
    }
}
export default CookiePageComponent