import React, { Component } from 'react';
import SocialMediaLinksData from '../../../../Model/Links/SocialMediaLinks';
// import ContactModal from '../../../../Shared/Modals/Contact';

class ContactPageComponent extends Component {
    render() {
        return (
            <div className="container text-center">
                <br/>
                <div className="row justify-content-md-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                        <h2 className="mb-4 display-5 text-center"><strong>Contact</strong></h2>
                        <br/>
                        <p className="text-secondary mb-5 text-center">Contact us from below</p>
                    </div>
                    <br/>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Email Us
                            </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <br/>
                                        <p className="card-text"><strong>xcolonstech@gmail.com</strong></p>
                                        <a href="mailto:xcolon.official@gmail.com" className="btn btn-primary">Send Mail</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Community
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="card text-center">
                                    <div className="card-body xc-contact-social-links">
                                        <br/>
                                        <section className="mb-4">
                                            {
                                                SocialMediaLinksData.map((item, index) => {
                                                    return <a data-mdb-ripple-init className="btn btn-link btn-floating btn-lg text-body m-1" role="button" data-mdb-ripple-color="dark" key={index} href={item.url} target="_blank" rel="noopener noreferrer"><i className={item.classname}></i></a>
                                                })
                                            }
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                Company
                            </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <br/>
                                        <h5 className="card-title">Incorporated Company</h5>
                                        <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                                        <a href="https://www.xcolon.in" className="btn btn-primary" target="_blank" rel="noopener noreferrer">Visit Website</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
            // <section className="bg-light py-3 py-md-5">
            //     <div className="container">
            //         <div className="row justify-content-md-center">
            //             <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
            //                 <h2 className="mb-4 display-5 text-center"><strong>Contact</strong></h2>
            //                 <br/>
            //                 <p className="text-secondary mb-5 text-center">The best way to contact us is to use our contact form below. Please fill out all of the required fields and we will get back to you as soon as possible.</p>
            //             </div>
            //         </div>
            //     </div>
            //     <div className="container">
            //         <div className="row justify-content-lg-center">
            //             <div className="col-12 col-lg-9">
            //                 <div className="bg-white border rounded shadow-sm overflow-hidden">
            //                     <ContactModal />
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </section>
        )
    }
}
export default ContactPageComponent