import React, { Component } from 'react';

class CopyrightPageComponent extends Component {
    render() {
        return (
            <div className="card">
                <br/>
                <div className="card-header">
                    <h4><strong>Copyright Notice</strong></h4>
                </div>
                <div className="card-body">
                    <p className="card-text"><b>Ownership of Content</b></p>
                    <p className="card-text">All content, materials, and software provided on this website (the "Site") are the property of XCOLON (OPC) PRIVATE LIMITED ("we," "us," or "our") and are protected by international copyright laws. This includes, but is not limited to, text, graphics, logos, icons, images, audio clips, video clips, software, and any other material or digital assets.</p>
                    <p className="card-text"><b>Copyright Statement</b></p>
                    <p className="card-text">Copyright © XCOLON (OPC) PRIVATE LIMITED. All rights reserved.</p>
                    <p className="card-text"><b>Use of Content</b></p>
                    <p className="card-text">You are permitted to view, download, and print pages from the Site for your personal, non-commercial use only. You may not:</p>
                    <ul>
                        <li>Republish material from the Site (including republication on another website).</li>
                        <li>Sell, rent, or sub-license material from the Site.</li>
                        <li>Reproduce, duplicate, copy, or otherwise exploit material on the Site for a commercial purpose.</li>
                        <li>Edit or otherwise modify any material on the Site.</li>
                        <li>Redistribute material from the Site, except for content specifically and expressly made available for redistribution.</li>
                    </ul>
                    <p className="card-text"><b>Trademarks</b></p>
                    <p className="card-text">All trademarks, service marks, and trade names used on the Site are proprietary to XCOLON (OPC) PRIVATE LIMITED unless stated otherwise. Unauthorized use of any trademark, service mark, or logo may be a violation of trademark laws.</p>
                    <p className="card-text"><b>Third-Party Content</b></p>
                    <p className="card-text">The Site may contain links to third-party websites or services that are not owned or controlled by us. We assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.</p>
                    <p className="card-text"><b>Reporting Copyright Infringements</b></p>
                    <p className="card-text"><b>If you believe that any material on the Site infringes upon any copyright you own or control, you may file a notification of such infringement with our technical team at:</b></p>
                    <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                    <p className="card-text">Email: info@xcolon.org</p>
                    <p className="card-text"><b>Please include the following information in your notification:</b></p>
                    <ul>
                        <li>A description of the copyrighted work that you claim has been infringed.</li>
                        <li>A description of where the material that you claim is infringing is located on the Site.</li>
                        <li>Your address, telephone number, and email address.</li>
                        <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
                        <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
                        <li>An electronic or physical signature of the person authorized to act on behalf of the copyright owner.</li>
                    </ul>
                    <p className="card-text"><b>Changes to This Copyright Notice</b></p>
                    <p className="card-text">We may update our Copyright Notice from time to time. We will notify you of any changes by posting the new Copyright Notice on this page. You are advised to review this Copyright Notice periodically for any changes. Changes to this Copyright Notice are effective when they are posted on this page.</p>
                    <p className="card-text"><b>Contact Us</b></p>
                    <p className="card-text">If you have any questions about this Copyright Notice, please contact us:</p>
                    <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                    <p className="card-text">Email: info@xcolon.org</p>
                    <p className="card-text"><b>By using our Site, you hereby consent to our Copyright Notice and agree to its terms. If you do not agree to this Copyright Notice, you should not use our Site.</b></p>
                    <br/>
                </div>
            </div>
        )
    }
}
export default CopyrightPageComponent