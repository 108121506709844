import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import RoutesData from '../../Model/Routes/RoutesData';
import RoutesComponent from '../../Contents/Components/Routes/RoutesComponent';

class Layout extends Component {
  render() {
    let routesdata = RoutesData;
    return (
      <div className="xc-layout">
        <Header/>
        <div className="container-fluid xc-app-container">
          <Routes>
            {
              routesdata.map((item, index) => {
                return <Route key={index} path={item.route} Component={() => <RoutesComponent page={item.route} />} />
              })
            }
          </Routes>
        </div>
        <Footer/>
      </div>
    );
  } 
}

export default Layout;