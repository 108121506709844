import React, { Component } from 'react';

class TermsPageComponent extends Component {
    render() {
        return (
            <div className="card">
                <br/>
                <div className="card-header">
                    <h4><strong>Terms & Conditions</strong></h4>
                </div>
                <div className="card-body">
                    <div className="card-title">Copyright © XCOLON. All Rights Reserved.</div>
                    <br/>
                    <p className="card-text">Welcome to the website of XCOLON (OPC) PRIVATE LIMITED ("Company", "we", "our", "us"). These terms and conditions ("Terms") govern your use of our website ("Site"). By accessing or using the Site, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Site.</p>
                    <p className="card-text"><b>Use of the Site</b></p>
                    <ul>
                        <li><b>Eligibility : </b> By using the Site, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.</li>
                        <li><b>License to Use the Site : </b> We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Site for your personal, non-commercial use.</li>
                        <li><b>Prohibited Uses : </b> You agree not to use the Site for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Site in any way that could damage the Site, the services, or the general business of the Company.</li>
                    </ul>
                    <p className="card-text"><b>Intellectual Property</b></p>
                    <ul>
                        <li><b>Ownership : </b> All content on the Site, including text, graphics, logos, images, audio clips, video clips, software, and other materials, is the property of XCOLON (OPC) PRIVATE LIMITED and is protected by international copyright laws.</li>
                        <li><b>Trademarks : </b> All trademarks, service marks, and trade names used on the Site are proprietary to XCOLON (OPC) PRIVATE LIMITED unless stated otherwise. Unauthorized use of any trademark, service mark, or logo may be a violation of trademark laws.</li>
                    </ul>
                    <p className="card-text"><b>User Content</b></p>
                    <ul>
                        <li><b>User-Generated Content : </b> You may be able to submit or post content (such as comments, reviews, etc.) on the Site. You retain ownership of any intellectual property rights that you hold in that content.</li>
                        <li><b>License to Use Content : </b> By submitting or posting content on the Site, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, publish, and distribute such content for any purpose.</li>
                        <li><b>Prohibited Content : </b> You agree not to submit or post any content that is illegal, offensive, defamatory, or infringes on the rights of any third party.</li>
                    </ul>
                    <p className="card-text"><b>Disclaimers</b></p>
                    <ul>
                        <li><b>No Warranties : </b> The Site is provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, regarding the Site, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                        <li><b>Limitation of Liability : </b> In no event shall XCOLON (OPC) PRIVATE LIMITED be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Site.</li>
                    </ul>
                    <p className="card-text"><b>Indemnification</b></p>
                    <p className="card-text">You agree to indemnify and hold XCOLON (OPC) PRIVATE LIMITED harmless from any claims, losses, damages, liabilities, including legal fees, arising out of your use or misuse of the Site, your violation of these Terms, or your violation of any rights of a third party.</p>
                    <p className="card-text"><b>Changes to These Terms</b></p>
                    <p className="card-text">We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page.</p>
                    <p className="card-text"><b>Governing Law</b></p>
                    <p className="card-text">These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.</p>
                    <p className="card-text"><b>Contact Us</b></p>
                    <p className="card-text">If you have any questions about these Terms, please contact us:</p>
                    <p className="card-text">XCOLON (OPC) PRIVATE LIMITED</p>
                    <p className="card-text">Email: info@xcolon.org</p>
                    <p className="card-text"><b>By using our Site, you hereby consent to our Terms and Conditions and agree to its terms. If you do not agree to these Terms, you should not use our Site.</b></p>
                </div>
            </div>
        )
    }
}
export default TermsPageComponent