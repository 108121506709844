import React, { Component } from 'react';

class HelpPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Help &nbsp; : &nbsp; Rendered Help Component</p>
            </div>
        )
    }
}
export default HelpPageComponent