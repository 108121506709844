import React, { Component } from 'react';

class RegistrationPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Registration &nbsp; : &nbsp; Rendered Registration Component</p>
            </div>
        )
    }
}
export default RegistrationPageComponent