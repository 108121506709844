import React, { Component } from 'react';

class ConnectPageComponent extends Component {
    render() {
        return (
            <div>
                <p>Connect &nbsp; : &nbsp; Rendered Connect Component</p>
            </div>
        )
    }
}
export default ConnectPageComponent