import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Shared/Layout/Layout';

const AppRoutes = () => {
  return (
    <Routes>
        <Route path="/*" element={<Layout />} />
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
