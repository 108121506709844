import React, { Component } from 'react';

class PageNotFoundComponent extends Component {
    render() {
        return (
            <div>
                <p>Page Not Found &nbsp; : &nbsp; Rendered Page Not Found Component</p>
            </div>
        )
    }
}
export default PageNotFoundComponent